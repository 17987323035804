<template>
  <main class="ma-4 elevation-2" style="background-color: white">
    <v-tabs slider-color="main" riple show-arrows>
      <v-tab class="cursor-pointer">
        <div class="main--text">{{ translations.categories }}</div>
      </v-tab>
    </v-tabs>

    <v-divider></v-divider>

    <v-data-table
      hide-default-footer
      :headers="headers"
      :items="availableProjects"
      :items-per-page="pageSize"
      :update:options="{
        page: page,
        itemsPerPage: pageSize
      }"
      dense
    >
      <template v-slot:footer>
        <div class="d-flex justify-end align-center pa-2">
          <span class="mr-2">{{ page === 1 ? initialNumber : (page - 1) * 10 + 1 }} - {{ (page - 1) * 10 + availableProjects.length }} di {{ totalProjects }}</span>

          <v-btn @click="onPreviousTableChange()" class="mr-2" icon :disabled="page === 1">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn @click="onNextTableChange" class="" icon :disabled="page === totalPages"> <v-icon>mdi-chevron-right</v-icon></v-btn>
        </div>
      </template>

      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-btn color="main" class="white--text" height="32px" depressed @click="openSaveNewCategoryDialog()">
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            {{ translations.newCategory }}
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.active="{ item }">
        <div class="d-flex justify-space-between">
          <v-checkbox :disabled="!item.canDeactivate" v-model="item.active" dense color="main" @change="value => changeCategoryActiveStatus(item, value)"></v-checkbox>
          <div class="d-flex">
            <v-icon v-if="item.canDeactivate" small class="mr-2" @click="openEditCategoryDialog(item)">
              mdi-pencil
            </v-icon>
            <v-icon v-if="item.canDeactivate" small color="error lighten-1" class="mr-2" @click="deleteCategory(item)">
              mdi-delete
            </v-icon>
          </div>
        </div>
      </template>
    </v-data-table>
    <v-dialog v-model="dialog" max-width="400px">
      <v-card class="pa-2">
        <v-card-title>
          <v-row class="ma-2" justify="center">
            <h3 class="main--text">
              {{ dialogTitle }}
            </h3>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-col class="pa-0">
            <b>{{ translations.name }}</b>
            <v-text-field v-model="category.label" outlined hide-details dense></v-text-field>
          </v-col>
          <v-col class="pa-0 mt-4">
            <b>{{ translations.code }}</b>
            <v-text-field v-model="category.keyCode" outlined hide-details dense></v-text-field>
          </v-col>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="main" class="white--text" depressed :disabled="!category.label || !category.keyCode" @click="saveCategory(category)">
            {{ translations.confirm }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row class="ma-0" align="center" justify="center">
      <v-col class="pa-2" cols="auto">
        <v-alert v-model="showErrorAlert" dense text type="error" dismissible>{{ translations.cannotDeleteCategory }}</v-alert>
      </v-col>
    </v-row>
  </main>
</template>

<script>
import projectsQuery from "@/graphql/Projects.gql";
import saveCategoryMutation from "@/graphql/SaveCategoryProject.gql";
import deleteProjectMutation from "@/graphql/DeleteProject.gql";
import changeCategoryActiveStatusMutation from "@/graphql/ChangeCategoryActiveStatus.gql";
import { translations } from "@/utils/common";
import { mdiPlus } from "@mdi/js";

export default {
  apollo: {
    projects: {
      query: projectsQuery,
      variables() {
        return {
          fetchNonCategory: false,
          fetchCategory: true,
          page: this.page,
          pageSize: this.pageSize,
          activeStatus: true
        };
      },
      fetchPolicy: "network-only",
      result({ data }) {
        const { content, hasNext, totalPages, totalElements } = data.projects;
        this.totalProjects = totalElements;
        this.totalPages = totalPages;
        this.availableProjects = content;
        this.hasNext = hasNext;
      }
    }
  },

  data: () => ({
    headers: [
      { text: translations.name, value: "label" },
      { text: translations.code, value: "keyCode" },
      { text: translations.active, value: "active" }
    ],
    category: {
      label: "",
      keyCode: ""
    },
    dialog: false,
    dialogTitle: translations.newCategory,
    showErrorAlert: false,
    availableProjects: [],
    hasNext: true,
    page: 1,
    pageSize: 10,
    totalProjects: 0,
    totalPages: 0
  }),

  methods: {
    async saveCategory(categoryProject) {
      await this.$apollo
        .mutate({
          mutation: saveCategoryMutation,
          variables: { categoryRequestObject: categoryProject }
        })
        .then(res => {
          this.dialog = false;
          const newCategory = res.data.saveCategoryProject;
          const index = this.availableProjects.findIndex(cat => cat.id == newCategory.id);
          if (index == -1) this.availableProjects.push(newCategory);
          else {
            this.availableProjects[index] = newCategory;
            this.availableProjects.splice(index, 1, newCategory);
          }
          this.incrementPages();
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.log(error);
        });
    },

    incrementPages() {
      this.totalProjects = this.availableProjects.length;
      this.hasNext = true;
      this.totalPages = Math.ceil(this.availableProjects.length / this.pageSize);
    },

    openSaveNewCategoryDialog() {
      this.dialog = true;
      this.category = {
        label: "",
        keyCode: ""
      };
      this.dialogTitle = this.translations.newCategory;
    },

    openEditCategoryDialog(category) {
      this.dialog = true;

      this.category = {
        id: category.id,
        label: category.label,
        keyCode: category.keyCode
      };

      this.dialogTitle = this.translations.edit + " " + category.label;
    },

    async deleteCategory(category) {
      await this.$apollo
        .mutate({
          mutation: deleteProjectMutation,
          variables: { projectId: category.id }
        })
        .then(() => {
          const index = this.availableProjects.indexOf(category);
          this.availableProjects.splice(index, 1);
          this.totalProjects -= 1;
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.log(error);
          this.showErrorAlert = true;
          setTimeout(() => (this.showErrorAlert = false), 6000);
        });
    },

    async changeCategoryActiveStatus(category, active) {
      await this.$apollo
        .mutate({
          mutation: changeCategoryActiveStatusMutation,
          variables: { categoryId: category.id, active: active }
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.log(error);
          category.active = !active;
        });
    },

    onNextTableChange() {
      if (!this.hasNext) return;
      this.page++;
    },

    onPreviousTableChange() {
      if (this.page === 1) return;
      this.page--;
    }
  },

  computed: {
    translations: () => translations,
    icons: () => ({
      mdiPlus
    }),

    initialNumber() {
      if (this.availableProjects.length === 0) return 0;

      return 1;
    }
  }
};
</script>
